<template>
    <header class="fixed z-50 w-full backdrop-blur-lg m-0 p-0 bg-white/60 items-center justify-between">
      <!-- logo - start -->
      <a href="/" class="inline-flex items-center gap-2.5 text-2xl font-bold text-black md:text-3xl" aria-label="logo">
        <img src="@/assets/logo.png" class="w-20"/>
      </a>
      <!-- logo - end -->

      <!-- buttons - start -->
      <!--<a href="#" class="hidden rounded-lg bg-gray-200 px-8 py-3 text-center text-sm font-semibold text-gray-500 outline-none ring-indigo-300 transition duration-100 hover:bg-gray-300 focus-visible:ring active:text-gray-700 md:text-base lg:inline-block"></a>-->
      <!-- buttons - end -->
    </header>
</template>

<script>
export default {
  name: 'navBar',
  props: {
    msg: String
  }
}
</script>

